<template>
  <div class="container-fluid py-3">
    <div class="row mb-3">
      <div class="col-4 col-sm-3 mb-5">
        <img class="img-fluid" src="../../assets/images/premium.png" alt="premium selected" />
      </div>
      <div class="col-8 col-sm-3 mb-5">
        <b-form @submit.prevent="buy()">
          <h5>{{ $tc('priceForMonth', premium.month, {month: premium.month }) }}</h5>
          <div class="row mb-2">
            <div class="col-12">
              <div class="form-group">
                <label for="price">{{ $t('price') }}</label>
                <b-input-group size="lg">
                  <b-input-group-text slot="prepend">
                    <i class="fa fa-usd px-3"></i>
                  </b-input-group-text>
                  <b-form-input
                    type="text"
                    name="price"
                    id="price"
                    readonly
                    v-validate="'required'"
                    :state="validateState('price')"
                    v-model="premium.price"
                  ></b-form-input>
                </b-input-group>
                <span class="error-inputs">{{ errors.first('price') }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="number">{{ $t('cardNumber') }}</label>
                <b-input-group size="lg">
                  <b-input-group-text slot="prepend">
                    <i class="fa fa-credit-card px-3"></i>
                  </b-input-group-text>
                  <b-form-input
                    type="text"
                    name="number"
                    id="number"
                    v-validate="'required'"
                    :state="validateState('number')"
                    v-model="payload.number"
                  ></b-form-input>
                </b-input-group>
                <span class="error-inputs">{{ errors.first('number') }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-8">
                  <label for="month">{{ $t('expirationDate') }}</label>
                  <div class="row">
                    <div class="col-6">
                      <b-form-input
                        type="text"
                        name="month"
                        id="month"
                        v-validate="'required'"
                        :state="validateState('month')"
                        v-model="payload.month"
                        placeholder="MM"
                      ></b-form-input>
                      <span class="error-inputs">{{ errors.first('month') }}</span>
                    </div>
                    <div class="col-6">
                      <b-form-input
                        type="text"
                        name="year"
                        id="year"
                        v-validate="'required'"
                        :state="validateState('year')"
                        v-model="payload.year"
                        placeholder="YYYY"
                      ></b-form-input>
                      <span class="error-inputs">{{ errors.first('year') }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label for="cvc">CVC</label>
                  <b-form-input
                    type="text"
                    name="cvc"
                    id="cvc"
                    v-validate="'required'"
                    :state="validateState('cvc')"
                    v-model="payload.cvc"
                    placeholder="***"
                  ></b-form-input>
                  <span class="error-inputs">{{ errors.first('cvc') }}</span>
                </div>
              </div>
            </div>
          </div>
          <b-button type="submit" variant="primary" block>{{ $t('toBuy') }}</b-button>
        </b-form>
      </div>
      <div class="col-12 col-sm-6 mb-5">
        <div class="row">
          <div class="col-4" v-for="(pre, i) in otherPremiums" :key="i">
            <div class="card">
              <img class="card-img-top" src="../../assets/images/premium.png" alt="Premium logo" />
              <div class="card-body text-center">
                <h5 class="py-2 mb-0">{{ pre.month }}</h5>
                <h3 class="py-2 mb-0">$ {{ pre.price | money }}</h3>
                <b-button
                  :disabled="!isAuthenticated"
                  variant="primary"
                  block
                  :to="{ name: 'PremiumBuy', params: { lang: lang, console: console, month: pre.month } }"
                >{{ $t('toBuy') }}</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["console", "lang", "isAuthenticated"])
  },
  data() {
    return {
      premiums: window.premiums,
      otherPremiums: [],
      premium: {},
      payload: {}
    };
  },
  created() {
    if (!this.isAuthenticated) {
      this.$router.push({
        name: "Premium",
        params: { lang: this.lang, console: this.console }
      });
      return;
    }
    this.changeMonth();
  },
  watch: {
    $route() {
      this.changeMonth();
    }
  },
  methods: {
    changeMonth() {
      const month = this.$route.params.month;
      const premium = this.premiums.filter(p => {
        return p.month === month;
      });
      if (premium.length > 0) {
        this.premium = premium[0];
        this.otherPremiums = this.premiums.filter(p => {
          return p.month !== month;
        });
      } else {
        this.$router.push({
          name: "Premium",
          params: { lang: this.lang, console: this.console }
        });
      }
    },
    buy() {
      this.$validator.validate().then(result => {
        if (result) {
          const month = this.$route.params.month;
          this.payload.month = month;
          const path = `auth/${this.lang}/console/${this.console}/shop/premium/${month}/buy`;
          this.$axios.post(path, this.payload).then(response => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS);
            this.payload.number = "";
            this.payload.cvc = "";
            this.payload.month = "";
            this.payload.number = "";
            this.payload.year = "";
          });
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      });
    }
  }
};
</script>
